// auth 2.0
import { removeDocument, fetchDocuments, setIsEditorNoteLoading, moveDocument } from "redux/actions/vdocsActions";
import { alertDeleteDocumentCheck, alertDeleteDocumentError } from "components/alerts";
import store from "redux/store";
import Sweetalert from "sweetalert2";
import { fetchCurrentFolder } from "../myNotes/folder/folder.utils";
import i18n from "config/i18n/i18n";
import { NoteLocationType } from "types/myNotes";

const t = i18n.t.bind(i18n);
interface folderListProps {
  cumulated_folder_name?: string;
  parent_keys: any[];
  folder_key: string;
}

export const getFolderList = (documentsList: any, cumFolderName: any) => {
  let folderList: folderListProps[] = [];
  let parentKeyList: string[] = [];
  if (!documentsList) return folderList;
  documentsList.forEach((item: any) => {
    let newCumFolderName = cumFolderName ? `${cumFolderName} / ${item.title}` : item.title;
    if (item.is_folder) {
      parentKeyList = [...parentKeyList, item.document_key];
      folderList.push({ parent_keys: parentKeyList, cumulated_folder_name: newCumFolderName, folder_key: item.document_key });
      folderList = [...folderList, ...getFolderList(item.documents, newCumFolderName)];
    }
    if (item.is_folder && item.documents) {
    }
  });
  return folderList;
};

export const moveToFolderHandler = async ({ documentKey, target, location }: { documentKey: string; target: string; location: NoteLocationType }) => {
  const documents = store.getState().vdocs.documents;
  const getFolderOptionTags = () => {};

  getFolderOptionTags();
  const { currentFolder } = store.getState().vdocs;
  let folderInputOptions: any = {
    root: t("MyNote", { ns: "NoteListView" }),
  };

  getFolderList(documents, "").forEach((item) => {
    folderInputOptions[item.folder_key] = item.cumulated_folder_name;
  });

  Sweetalert.fire({
    target: target,
    heightAuto: false,
    customClass: {
      container: "position-absolute",
    },
    title: t("MoveToFolderOption", { ns: "NoteListView" }),
    text: t("MoveToFolderModalDesc", { ns: "NoteListView" }),
    input: "select",
    inputValue: currentFolder?.document_key || "root",
    inputOptions: folderInputOptions,
    confirmButtonText: t("Move", { ns: "NoteListView" }),
    showCancelButton: true,
    cancelButtonText: t("Cancel", { ns: "NoteListView" }),
  }).then(async (result) => {
    if (result.isConfirmed) {
      Sweetalert.fire({
        target: target,
        heightAuto: false,
        customClass: {
          container: "position-absolute",
        },
        title: t("Updating", { ns: "NoteListView" }),
        timerProgressBar: true,
        didOpen: () => {
          Sweetalert.showLoading();
        },
      });

      const updatedDocumentData = await store.dispatch(
        moveDocument({
          parentKey: result.value,
          documentKeys: [documentKey],
          location: location,
          from: "single selection",
        })
      );

      if (updatedDocumentData.error_message) {
        Sweetalert.close();
        return;
      }

      await store.dispatch(fetchDocuments());
      if (currentFolder) {
        fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
      } else {
        fetchCurrentFolder({ documentKey: "root" });
      }
      Sweetalert.close();
    }
  });
};

export const onRemoveNote = ({ target, title, documentKey, history, isFolder, location, from }: any) => {
  const applicationType = store.getState().slidGlobal.applicationType;
  const currentFolder = store.getState().vdocs.currentFolder;
  const currentDocument = store.getState().vdocs.currentDocument;

  let nextDocumentKey: string;
  if (documentKey === currentDocument?.document_key) {
    const documents = currentFolder.documents.filter((document: any) => !document.is_folder);
    documents.forEach((note: any, index: number) => {
      if (note.document_key === currentDocument?.document_key) {
        if (documents.length === 1) nextDocumentKey = "new";
        else if (index + 1 === documents.length) {
          nextDocumentKey = documents[index - 1].document_key;
        } else {
          nextDocumentKey = documents[index + 1].document_key;
        }
      }
    });
  }

  alertDeleteDocumentCheck({ target, title, isFolder }).then(async (result) => {
    if (result.isDenied) {
      // NOTE : It means confirmed in delete.
      const isRemovingCurrentDocument = documentKey === currentDocument?.document_key;
      if (isRemovingCurrentDocument) {
        if (applicationType === "extension" || window.location.pathname.includes("vdocs")) {
          history.push(`/vdocs/${nextDocumentKey || "new"}`);
        } else {
          history.push(`/docs/${nextDocumentKey || "new"}`);
        }
        store.dispatch(setIsEditorNoteLoading(true));
      }

      let documentDeleteData = await store.dispatch(
        removeDocument({
          documentKey,
          location: location,
          from: from,
          isFolder: false,
        })
      );

      if (documentDeleteData.error_message) {
        alertDeleteDocumentError({ target: `.${target}` });
        return;
      }
    }

    store.dispatch(fetchDocuments());
  });
};
