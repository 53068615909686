import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Typography17, Icon, Tooltip } from "@slid/slid-ips";
import MyNotesButton from "components/buttons/MyNotesButton";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import { isTouchDevice } from "utils/utils";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { createDocument, createFolder, fetchDocument, fetchDocuments, moveDocument, setIsEditorOptionOpen, setIsListOpen } from "redux/actions/vdocsActions";
import useMyNoteStore from "store/useMyNoteStore";
import { useAppDispatch, useAppSelector } from "hooks";
import { useSortDocument } from "hooks/useSortDocument";
import { useMediaQuery } from "react-responsive";
import Sweetalert from "sweetalert2";
import { useSaveDocumentAndCreateHistory } from "hooks/useSaveDocumentAndCreateHistory";
import VideoDocumentEditorDropdownMenu from "./VideoDocumentEditorDropdownMenu";

const rotation = {
  closed: { rotate: 0 },
  open: { rotate: 180 },
};

const menuUlVariants = {
  initial: {
    height: 0,

    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },
  animate: {
    height: "auto",

    transition: {
      duration: 0.15,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const VideoDocumentEditorHeader = () => {
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { t } = useTranslation();
  const history = useHistory();
  const { saveDocument } = useSaveDocumentAndCreateHistory();
  const dispatch = useAppDispatch();

  const [currentCumFolderList, setCurrentCumFolderList] = useState([]);
  const [currentCumFolderName, setCurrentCumFolderName] = useState(t("NoFolder", { ns: "VideoNote" }));
  const [isFolderSelectorActive, setIsFolderSelectorActive] = useState(false);

  const { applicationType, lang, screenOrientation } = useAppSelector((state) => state.slidGlobal);
  const { isEditorOptionOpen, currentDocument, documents } = useAppSelector((state) => state.vdocs);

  const { showInsufficientPrivilegeModal, confirmPrivilege } = useConfirmPrivilege();
  const { setDeleteNoteLocation } = useMyNoteStore();
  const { recursiveSortDocuments } = useSortDocument();

  useEffect(() => {
    if (!documents || documents.length === 0) return;
    getFolderOptionTags();
  }, [documents]);

  useEffect(() => {
    if (documents && documents.length > 0) {
      const newDocumentsPath = { new: t("NoFolder", { ns: "VideoNote" }) };
      const setNoteFolderName = ({ folder, cumPathName }) => {
        folder.documents.forEach((item) => {
          if (item["is_folder"]) {
            let newCumPathName = cumPathName ? `${cumPathName} / ${item["title"]}` : `${item["title"]}`;
            setNoteFolderName({
              folder: item,
              cumPathName: newCumPathName,
            });
          } else {
            let newCumPathName = cumPathName ? `${cumPathName}` : t("NoFolder", { ns: "VideoNote" });
            newDocumentsPath[item["document_key"]] = newCumPathName;
          }
        });
      };
      setNoteFolderName({
        folder: {
          documents: documents,
        },
        cumPathName: null,
      });
      setCurrentCumFolderName(currentDocument ? newDocumentsPath[currentDocument["document_key"]] : newDocumentsPath["new"]);
    }
  }, [documents, currentDocument]);

  const getFolderOptionTags = () => {
    let folderOptionTags = [];
    if (!documents) return folderOptionTags;

    folderOptionTags = getChildOptionTags({
      folder: {
        documents,
      },
      cumFolderName: null,
    });

    setCurrentCumFolderList(folderOptionTags);

    return folderOptionTags;
  };

  const getChildOptionTags = ({ folder, cumFolderName }) => {
    let childOptionTags: any = [];

    const sortedDocument = recursiveSortDocuments(folder.documents);

    sortedDocument.forEach((item) => {
      let newCumFolderName = cumFolderName ? `${cumFolderName} / ${item["title"]}` : item["title"];
      if (item["is_folder"]) {
        childOptionTags.push(
          <NoteFolderListItem
            key={item["document_key"]}
            onClick={() => {
              if (!confirmPrivilege(SlidFeatures.folderMove)) return showInsufficientPrivilegeModal();
              onDocumentFolderChange(item["document_key"]);
              setIsFolderSelectorActive(false);
            }}
          >
            <Typography17 text={newCumFolderName} color={`--gray15`} weight={currentDocument && item["document_key"] === currentDocument["parent_key"] ? 700 : 400} />
          </NoteFolderListItem>
        );

        childOptionTags = [
          ...childOptionTags,
          ...getChildOptionTags({
            folder: item,
            cumFolderName: newCumFolderName,
          }),
        ];
      }
    });
    return childOptionTags;
  };

  const onDocumentFolderChange = async (selectedFolderDocumentKey) => {
    let documentKey = currentDocument ? currentDocument["document_key"] : null;
    if (!currentDocument) {
      const newDocument = await dispatch(createDocument({ origin: "vdocs" }));
      history.replace(`./${newDocument["document_key"]}`);
      documentKey = newDocument["document_key"];
    }
    await dispatch(
      moveDocument({
        parentKey: selectedFolderDocumentKey,
        documentKeys: [documentKey],
        location: "Video note page",
        from: "single selection",
      })
    );
    dispatch(
      fetchDocument({
        documentKey: documentKey,
      })
    );
    dispatch(fetchDocuments());
  };

  const openNewFolderPopup = () => {
    Sweetalert.fire({
      target: !isMobile ? ".video-document-container" : undefined,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: lang === "ko" ? "새 폴더 생성" : "Create new folder",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: lang === "ko" ? "폴더 이름을 입력하세요." : "Enter title",
      },
      showCancelButton: true,
      confirmButtonText: lang === "ko" ? "생성" : "Create",
      cancelButtonText: lang === "ko" ? "취소" : "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Sweetalert.fire({
          title: lang === "ko" ? "생성 중..." : "Creating...",
          html: lang === "ko" ? "새 폴더를 생성 중입니다..." : `Creating new folder...`,
          target: ".video-document-container",
          heightAuto: false,
          customClass: {
            container: "position-absolute",
          },
          didOpen: () => {
            Sweetalert.showLoading();
          },
        });
        const createdFolder = await dispatch(
          createFolder({
            title: result.value,
            parentKey: "root",
            origin: "vdocs",
          })
        );

        // move current document to created folder
        if (!currentDocument) {
          const newDocument = await dispatch(createDocument({ origin: "vdocs" }));
          history.replace(`./${newDocument["document_key"]}`);
        }
        await dispatch(
          moveDocument({
            parentKey: createdFolder["document_key"],
            documentKeys: [currentDocument["document_key"]],
            location: "Video note page",
            from: "single selection",
          })
        );
        dispatch(
          fetchDocument({
            documentKey: currentDocument["document_key"],
          })
        );
        history.push(`/vdocs/${currentDocument["document_key"]}`);
        dispatch(fetchDocuments());
        Sweetalert.close();
      }
    });
  };

  return (
    <HeaderPositioner isVertical={screenOrientation === "vertical"}>
      <HeaderContainer applicationType={applicationType}>
        {isFolderSelectorActive && <FolderDropdownWrapper onClick={() => setIsFolderSelectorActive(false)} />}
        <HeaderLeftContainer>
          {/**TODO : Change 2.0.0 to remote config */}
          <Tooltip title={t("OpenNoteList", { ns: "Header" })} placement={`bottom-start`} onShow={() => !isTouchDevice()}>
            <ListOpenIcon
              draggable={false}
              onClick={() => {
                dispatch(setIsListOpen(true));
              }}
              slid-cy={`editor-list-toggle-btn`}
            >
              <Icon icon={`list24`} color={`--gray15`} />
            </ListOpenIcon>
          </Tooltip>
          {(!isMobile || applicationType === "desktop") && (
            <NoteFolderSelectorContainer>
              <NoteFolderSelectorButton
                onClick={() => {
                  setIsFolderSelectorActive(!isFolderSelectorActive);
                }}
              >
                <Typography17 text={currentDocument ? currentCumFolderName : t("NoFolder", { ns: "VideoNote" })} color={`--gray15`} weight={700} />
                <NoteFolderSelectorButtonIconContainer variants={rotation} animate={isFolderSelectorActive ? "open" : "closed"} transition={{ duration: 0.3 }}>
                  <Icon icon={`chevronDown24`} color={`--gray15`} />
                </NoteFolderSelectorButtonIconContainer>
              </NoteFolderSelectorButton>
              {isFolderSelectorActive && (
                <AnimatePresence>
                  <NoteFolderSelectorDropdown variants={menuUlVariants} initial="initial" animate="animate" exit="initial">
                    <NoteFolderListItem
                      onClick={() => {
                        if (!confirmPrivilege(SlidFeatures.folderCreation)) return showInsufficientPrivilegeModal();
                        openNewFolderPopup();
                        setIsFolderSelectorActive(false);
                      }}
                    >
                      <Typography17 text={t("SaveInNewFolder", { ns: "VideoNote" })} color={`--gray9`} />
                    </NoteFolderListItem>
                    <NoteFolderListItem
                      onClick={() => {
                        if (!confirmPrivilege(SlidFeatures.folderMove)) return showInsufficientPrivilegeModal();
                        onDocumentFolderChange("root");
                        setIsFolderSelectorActive(false);
                      }}
                    >
                      <Typography17 text={t("NoFolder", { ns: "VideoNote" })} color={`--gray9`} />
                    </NoteFolderListItem>
                    {currentCumFolderList}
                  </NoteFolderSelectorDropdown>
                </AnimatePresence>
              )}
            </NoteFolderSelectorContainer>
          )}
        </HeaderLeftContainer>
        <HeaderRightContainer>
          {(!isMobile || applicationType === "desktop") && (
            <MyNoteButtonContainer data-testid={`cognito-button-container`}>
              <MyNotesButton />
            </MyNoteButtonContainer>
          )}
          <Tooltip title={t("EditorOptionMenuTooltip", { ns: "Header" })} placement={`bottom-end`} onShow={() => !isTouchDevice()}>
            <VideoNoteDropdownButtonContainer
              onClick={async () => {
                trackEvent({
                  eventType: "Click editor option dropdown menu",
                });
                dispatch(setIsEditorOptionOpen(!isEditorOptionOpen));
              }}
            >
              <Icon icon={`more24`} color={`--gray15`} />
            </VideoNoteDropdownButtonContainer>
          </Tooltip>
          {isEditorOptionOpen && <VideoDocumentEditorDropdownMenu />}
        </HeaderRightContainer>
      </HeaderContainer>
    </HeaderPositioner>
  );
};

const HeaderPositioner = styled.div<{ isVertical: boolean }>`
  height: 56px;
  max-width: 100%;
  border-bottom: 1px solid var(--gray3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 12px 32px;

  ${(props) =>
    props.isVertical &&
    css`
      height: auto;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;
      background-color: white;
      padding: 12px;
      border-bottom: 0;
    `}
`;

const HeaderContainer = styled.div<{ applicationType: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.applicationType !== "desktop" &&
    css`
      @media screen and (max-width: 799px) {
        padding: 0px 12px;
      }
    `}
`;

const FolderDropdownWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 154px);
`;

const NoteFolderSelectorContainer = styled.div`
  position: relative;
  max-width: calc(100% - 28px);
  flex: 1;
`;

const NoteFolderSelectorButton = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  padding: 0;
  /* e of reset button  */
  width: 100%;

  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NoteFolderSelectorButtonIconContainer = styled(motion.div)`
  margin-left: 4px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray3);
  }

  &:active {
    background-color: var(--gray2);
  }
`;

const ListOpenIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
  width: 28px;
  height: 28px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray3);
  }

  &:active {
    background-color: var(--gray2);
  }
`;

const NoteFolderSelectorDropdown = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 8px 0px;
  width: 280px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  background: #ffffff;
  box-shadow: var(--boxShadow2);
  border-radius: 8px;
  z-index: 3;

  overflow-x: hidden;
  overflow-y: visible;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: fit-content;

  a {
    display: flex;
    align-items: center;
  }
`;

const NoteFolderListItem = styled.li`
  width: 100%;
  padding: 4px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray3);
  }
`;

const MyNoteButtonContainer = styled.div`
  display: flex;
  margin-right: 15px;
`;

const VideoNoteDropdownButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

export default VideoDocumentEditorHeader;
