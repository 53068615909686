import Sweetalert from "sweetalert2";
import store from "redux/store";
import { fetchDocuments, updateFolder, updateDocument, removeDocument } from "redux/actions/vdocsActions";
import { fetchCurrentFolder } from "./folder/folder.utils";
import { showRemoveDocumentModal } from "utils/modal/myNotesModal";
import { alertDeleteDocumentError } from "components/alerts";
import { isMobile } from "react-device-detect";
import { trackEvent } from "utils/eventTracking";
import { SuccessEventType } from "types/eventTracking/successEventType";

export const fetchMyNotes = async () => {
  const documentsData = await store.dispatch(fetchDocuments());
  if (documentsData.error_message) {
    return null;
  }
  return documentsData;
};

export const updateDocumentTitle = ({ documentKey, title, isFolder, getSearchResults, searchKeywords }: any) => {
  const { lang } = store.getState().slidGlobal;
  const { currentFolder } = store.getState().vdocs;

  Sweetalert.fire({
    title: lang === "ko" ? `${isFolder ? "폴더" : "노트"} 이름 변경` : `Change ${isFolder ? "folder title" : "note title"}`,
    input: "text",
    inputPlaceholder: lang === "ko" ? "변경할 이름을 입력해주세요." : "Enter new title",
    inputValue: title,
    confirmButtonText: lang === "ko" ? "업데이트" : "Update",
    showCancelButton: true,
    cancelButtonText: lang === "ko" ? "취소" : "Cancel",
    preConfirm: (value) => {
      if (!value) {
        Sweetalert.showValidationMessage(lang === "ko" ? "이름을 입력해주세요!" : "You need to write something!");
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      Sweetalert.fire({
        title: lang === "ko" ? "업데이트 중..." : "Updating...",
        timerProgressBar: true,
        didOpen: () => {
          Sweetalert.showLoading();
        },
      });

      const updatedDocumentData = isFolder
        ? await store.dispatch(
            updateFolder({
              documentKey: documentKey,
              title: result.value,
              origin: "my docs",
              currentDocumentKey: "",
            })
          )
        : await store.dispatch(
            updateDocument({
              documentKey: documentKey,
              //@ts-ignore
              title: result.value,
              origin: "my docs",
            })
          );
      trackEvent({ eventType: isFolder ? SuccessEventType.RENAME_FOLDER : SuccessEventType.RENAME_NOTE });

      if (updatedDocumentData.error_message) {
        Sweetalert.close();
        return;
      }

      await store.dispatch(fetchDocuments());
      if (currentFolder) {
        fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
      } else {
        fetchCurrentFolder({ documentKey: "root" });
      }

      Sweetalert.close();

      if (searchKeywords) {
        getSearchResults(searchKeywords);
      }
    }
  });
};

export const removeNote = async ({ documentKeys, getSearchResults, searchKeywords, showModal, closeModal, isFolder = false, from }: any) => {
  const { currentFolder, editorWrapperClassName } = store.getState().vdocs;

  const onConfirmRemove = async () => {
    for (let documentKey of documentKeys) {
      const documentDeleteData = await store.dispatch(
        removeDocument({
          documentKey,
          location: "My notes",
          from: from,
          isFolder: isFolder,
        })
      );

      if (documentDeleteData.error_message) {
        alertDeleteDocumentError({ target: isMobile ? "body" : `.${editorWrapperClassName}` });
        return;
      }
    }

    await store.dispatch(fetchDocuments());
    if (currentFolder) {
      fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
    } else {
      fetchCurrentFolder({ documentKey: "root" });
    }

    // if user is currently searching, do search again.
    if (searchKeywords) {
      getSearchResults(searchKeywords);
    }
  };

  await showRemoveDocumentModal({
    documentKeys,
    showModal,
    closeModal,
    onConfirmRemove,
  });
};
