/* eslint-disable react-hooks/exhaustive-deps */
// auth 2.0
import React, { useState, useEffect, useRef, memo } from "react";
import styles from "./FolderItem.module.scss";
import DocumentItem from "./DocumentItem";
import { useAppDispatch, useAppSelector } from "hooks";
import { updateFolder } from "redux/actions/vdocsActions";
import { alertRenameFolder, alertRenameFolderError } from "components/alerts";
import { Typography15, Icon } from "@slid/slid-ips";
import { useMediaQuery } from "react-responsive";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import styled from "styled-components";
import { isTouchDevice } from "utils/utils";
import NoteListViewItemOptionMenu from "./NoteListViewItemOptionMenu";
import { useSortDocument } from "hooks/useSortDocument";
import { trackEvent } from "utils/eventTracking";
import { SuccessEventType } from "types/eventTracking/successEventType";

const FolderItem = memo(({ item, title, documentKey, childDocuments, currentDocument, currentDocumentKey, onUpdateDocument, openParentFolder, onDropDocument }) => {
  const dispatch = useAppDispatch();
  const { editorWrapperClassName } = useAppSelector((state) => state.vdocs);
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const { recursiveSortDocuments } = useSortDocument();

  const [currentTitle, setCurrentTitle] = useState(title);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [showOptionEditDropdown, setShowOptionEditDropdown] = useState(false);
  const [isFolderItemContainerHover, setIsFolderItemContainerHover] = useState(false);
  const [optionPositionValue, setOptionPositionValue] = useState(0);

  const optionEditIconWrapperRef = useRef(null);
  const folderColor = item.content && item.content !== "{}" ? JSON.parse(item.content).color : "--gray9";

  useEffect(() => {
    if (!currentDocument) return;
    if (documentKey === currentDocument["parent_key"]) {
      setIsOpen(true);
      if (openParentFolder) openParentFolder();
    }
  }, [currentDocument]);

  const getChildTags = () => {
    if (childDocuments && childDocuments.length > 0) {
      const folderListTags = [];
      const documentListTags = [];
      childDocuments.forEach((item) => {
        if (item.is_folder) {
          const sortedChildDocuments = recursiveSortDocuments(item.documents);

          folderListTags.push(
            <FolderItem
              item={item}
              title={item["title"]}
              documentKey={item["document_key"]}
              childDocuments={sortedChildDocuments}
              key={item["document_key"]}
              currentDocumentKey={currentDocumentKey}
              currentDocument={currentDocument}
              openParentFolder={() => {
                setIsOpen(true);
                if (openParentFolder) openParentFolder();
              }}
              onDropDocument={onDropDocument}
              onUpdateDocument={onUpdateDocument}
            />
          );
        } else {
          documentListTags.push(
            <DocumentItem
              item={item}
              title={item["title"]}
              documentKey={item["document_key"]}
              key={item["document_key"]}
              currentDocument={currentDocument}
              currentDocumentKey={currentDocumentKey}
              onUpdateDocument={onUpdateDocument}
            />
          );
        }
      });

      return (
        <div
          className={`${styles[`child-item-container`]} ${isOpen ? "" : "d-none"}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {folderListTags.concat(documentListTags)}
        </div>
      );
    } else {
      return (
        <div
          className={`${styles[`empty-child-item-container`]} ${isOpen ? "" : "d-none"}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          It's empty
        </div>
      );
    }
  };

  const updateFolderTitle = async ({ title, documentKey }) => {
    const oldTitle = currentTitle;
    setCurrentTitle(title);
    let updatedFolderData = await dispatch(
      updateFolder({
        documentKey: documentKey,
        title: title,
        currentDocumentKey: currentDocumentKey,
        origin: "list",
      })
    );
    trackEvent({ eventType: SuccessEventType.RENAME_FOLDER });

    if (updatedFolderData.error_message) {
      setCurrentTitle(oldTitle);
      alertRenameFolderError({ target: isMobile ? "body" : `.${editorWrapperClassName}` });
      return;
    }
  };

  const onRenameFolder = (event) => {
    event.stopPropagation();
    setShowOptionEditDropdown(false);
    if (!confirmPrivilege(SlidFeatures.folderTitleUpdate)) return showInsufficientPrivilegeModal();
    alertRenameFolder({ target: isMobile ? "body" : `.${editorWrapperClassName}`, currentTitle }).then((result) => {
      if (result.isConfirmed) {
        updateFolderTitle({
          title: result.value,
          documentKey: documentKey,
        });
      }
    });
  };

  return (
    <div
      className={`${styles[`folder-item-container`]} ${isHover ? styles[`hover`] : ""}`}
      onClick={(event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
      }}
      draggable={!isTouchDevice()}
      onDragOver={(event) => {
        if (isTouchDevice()) return;
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.getData("documentKey") === item?.document_key) return;
        setIsHover(true);
      }}
      onDragLeave={(event) => {
        setIsHover(false);
        event.preventDefault();
        event.stopPropagation();
      }}
      onDragStart={(event) => {
        if (isTouchDevice()) return;
        event.stopPropagation();
        event.dataTransfer.setData("documentKey", item.document_key);
      }}
      onDrop={(event) => {
        if (isTouchDevice()) return;
        onDropDocument(event, item);
        setIsHover(false);
      }}
      onDragEnd={() => setIsHover(false)}
      onMouseEnter={() => {
        if (isTouchDevice()) return;
        setIsFolderItemContainerHover(true);
      }}
      onMouseLeave={() => {
        if (isTouchDevice()) return;
        setIsFolderItemContainerHover(false);
      }}
    >
      <div
        className={styles[`parent-item-container`]}
        title={currentTitle}
        onDragOver={(event) => {
          event.preventDefault();
        }}
      >
        <div className={`d-flex align-items-center`} style={{ width: "85%" }}>
          <img alt={`${isOpen ? "opened" : "closed"} folder`} className={styles[`folder-arrow-icon`]} src={`/src/design/assets/slid_folder_${isOpen ? "open" : "close"}_arrow_icon.png`} />
          <div className={`${styles[`folder-icon`]}`}>
            <Icon icon={`filledFolder24`} color={folderColor} />
          </div>
          <Typography15 weight={400} text={currentTitle} color="--gray17" style={{ width: "80%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginLeft: "4px" }} />
        </div>
        <OptionEditIconContainer
          showOptionEditIcon={isFolderItemContainerHover || showOptionEditDropdown}
          onClick={(event) => {
            event.stopPropagation();
            // Set dropdown option menu's top position related to edit-icon
            setOptionPositionValue(optionEditIconWrapperRef.current.getBoundingClientRect().top);
            setShowOptionEditDropdown(true);
          }}
          ref={optionEditIconWrapperRef}
        >
          <Icon icon={`more28`} color={`--gray9`} />
        </OptionEditIconContainer>
      </div>
      {getChildTags()}
      {showOptionEditDropdown && (
        <NoteListViewItemOptionMenuWrapper onClick={() => setShowOptionEditDropdown(false)}>
          <NoteListViewItemOptionMenu onRenameDocument={onRenameFolder} documentKey={documentKey} optionPositionValue={optionPositionValue} />
        </NoteListViewItemOptionMenuWrapper>
      )}
    </div>
  );
});

export default FolderItem;

const NoteListViewItemOptionMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 3;
`;

const OptionEditIconContainer = styled.div`
  display: flex;
  visibility: ${(props) => (isTouchDevice() || props.showOptionEditIcon ? "inital" : "hidden")};
  align-items: center;
  justify-content: center;
`;
