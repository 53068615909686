// auth 2.0
import { isMobile } from "react-device-detect";
import { fetchMyNotes } from "../note.utils";
import { createFolder, fetchDocuments, moveDocument, setCurrentFolder, updateFolder } from "redux/actions/vdocsActions";
import { setPopupState } from "redux/modules/myNotesSlice";
import store from "redux/store";
import { SlidFolderDocument, SlidNoteDocument } from "types/document";
import { showMoveToFolderModal } from "utils/modal/myNotesModal";
import Sweetalert from "sweetalert2";
import i18n from "config/i18n/i18n";
import { SelectionType } from "types/myNotes";
const t = i18n.t.bind(i18n);

export const fetchCurrentFolder = async ({ documentKey }: any) => {
  const { documents } = store.getState().vdocs;

  if (documentKey === "root") {
    await store.dispatch(
      setCurrentFolder({
        documents,
        document_key: "root",
      })
    );
    return;
  }

  const shouldDispatchDocuments = false;
  let documentsData: any = await store.dispatch(fetchDocuments(documentKey, shouldDispatchDocuments));
  if (documentsData?.error_message) {
    return;
  }
  if (!documentsData || !documentsData.documents) {
    return;
  }
  await store.dispatch(setCurrentFolder(documentsData));
};

const applyIndentation = (folderPathMap) => {
  for (let id in folderPathMap) {
    const numParents = folderPathMap[id].text.split("/").length - 1;
    const indentation = " ".repeat(numParents);
    folderPathMap[id].text = `${indentation} ${folderPathMap[id].text.split("/")[numParents]}`;
  }

  return folderPathMap;
};

export const moveToFolder = async ({
  documentKeys,
  folderPathMap,
  showModal,
  closeModal,
  resetSelectedDocuments,
  documents,
  from,
}: {
  documentKeys: string[];
  folderPathMap: any;
  showModal: Function;
  closeModal: Function;
  resetSelectedDocuments: () => void;
  documents: SlidFolderDocument[] | SlidNoteDocument[];
  from: SelectionType;
}) => {
  const { currentFolder } = store.getState().vdocs;
  const selectedDocsParentKey = Array.from(new Set(documents.map((doc) => doc.parent_key)));

  const onConfirmMoveToFolder = async (selectedItem) => {
    if (!selectedItem) return;
    const updatedDocumentData = await store.dispatch(
      moveDocument({
        parentKey: selectedItem.id,
        documentKeys: documentKeys,
        location: "My notes",
        from: from,
      })
    );

    if (updatedDocumentData.error_message) {
      closeModal();

      return;
    }

    await store.dispatch(fetchDocuments());
    if (currentFolder) {
      fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
    } else {
      fetchCurrentFolder({ documentKey: "root" });
    }
    closeModal();
  };

  const FolderMapArr: {
    text: string;
    id: string;
    disabled: boolean;
  }[] = Object.entries(folderPathMap).map(([key, value]: [string, any]) => ({
    id: key,
    text: value.pathText,
    disabled: documentKeys.some((key) => value.parentKeys.includes(key)) || selectedDocsParentKey.includes(key),
  }));

  const indentedFolderMapArr = applyIndentation(FolderMapArr);

  // for mobile view
  let mobileFolderMapArr: any = {};

  Object.keys(folderPathMap).forEach((pathMapDocumentKey) => {
    // filter out current folder or the folder's child folders in options.
    if (folderPathMap[pathMapDocumentKey].parentKeys.includes(documentKeys)) {
      return;
    }
    mobileFolderMapArr[pathMapDocumentKey] = folderPathMap[pathMapDocumentKey].pathText;
  });

  isMobile
    ? Sweetalert.fire({
        title: t(documentKeys.length > 1 ? "MoveToFolderTitle_plural" : "MoveToFolderTitle", { ns: "MyNotes", count: documentKeys.length }),
        input: "select",
        inputOptions: mobileFolderMapArr,
        confirmButtonText: t("MoveToFolderPrimaryButton", { ns: "MyNotes" }),
        showCancelButton: true,
        cancelButtonText: t("MoveToFolderCancelButton", { ns: "MyNotes" }),
      }).then(async (result) => {
        if (result.isConfirmed) {
          Sweetalert.fire({
            title: t("Updating", { ns: "MyNotes" }),
            timerProgressBar: true,
            didOpen: () => {
              Sweetalert.showLoading();
            },
          });

          const updatedDocumentData = await store.dispatch(
            moveDocument({
              parentKey: result.value,
              documentKeys: documentKeys,
              location: "My notes",
              from: from,
            })
          );

          if (updatedDocumentData.error_message) {
            Sweetalert.close();
            return;
          }

          await store.dispatch(fetchDocuments());
          if (currentFolder) {
            fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
          } else {
            fetchCurrentFolder({ documentKey: "root" });
          }
          Sweetalert.close();
        }
      })
    : showMoveToFolderModal({
        options: indentedFolderMapArr,
        showModal,
        closeModal,
        onConfirmMoveToFolder,
        selectedItemLength: documentKeys.length,
        resetSelectedDocuments: resetSelectedDocuments,
        currentOption: selectedDocsParentKey.length === 1 ? indentedFolderMapArr.find((folder) => folder.id === selectedDocsParentKey[0]) : null,
      });
};

export const updateFolderColor = async ({ folder, color }: any) => {
  const { currentFolder } = store.getState().vdocs;
  const updatedFolderData = await store.dispatch(
    updateFolder({
      documentKey: folder.document_key,
      title: folder.title,
      origin: "my docs",
      currentDocumentKey: "",
      //@ts-ignore
      content: { color: color },
    })
  );

  if (updatedFolderData.error_message) {
    return;
  }

  await store.dispatch(fetchDocuments());
  if (currentFolder) {
    fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
  } else {
    fetchCurrentFolder({ documentKey: "root" });
  }
};

export const createNewFolder = async () => {
  const { currentFolder } = store.getState().vdocs;
  // @ts-ignore
  const title = document.querySelector(`#newFolderNameInput`).value;
  const parentKey = currentFolder ? currentFolder.document_key : "root";
  await store.dispatch(
    createFolder({
      title,
      parentKey,
      origin: "my docs",
    })
  );

  await store.dispatch(setPopupState(null));

  await fetchMyNotes();
  if (currentFolder) {
    await fetchCurrentFolder({ documentKey: parentKey });
  } else {
    await fetchCurrentFolder("root");
  }
};
